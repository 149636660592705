//import container component
//try and keep app.js clean
//https://tailwindcss.com/docs/guides/create-react-app

import Container from "./components/Container";



function App() {
  return (
    <div className="App">
      <Container>
      </Container>
    </div>
  );
}

export default App;
